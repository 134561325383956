export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectToken = (state) => state.auth.token;
export const selectIsLoading = (state) => state.auth.isLoading;
export const selectIsRefreshing = (state) => state.auth.isRefreshing;
export const selectError = (state) => state.auth.error;
export const selectRefreshError = (state) => state.auth.refreshError;
export const selectName = (state) => state.auth.user.name;
export const selectEmail = (state) => state.auth.user.email;
export const selectTheme = (state) => state.auth.user.theme;
export const selectPhotoUrl = (state) => state.auth.user.photoUrl;
